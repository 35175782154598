.empresa-sped {
  .ant-typography {
    margin-bottom: 0;
  }

  .filter-options {
    .ant-row {
      margin: 0;
    }
  }

  .ant-btn-link {
    width: 100%;
  }

  .download-sped-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
