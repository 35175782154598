@import "src/app/styles/variables";

.sidebar-menu.ant-menu {
  margin: 1rem 0;

  .ant-menu-item {
    display: flex;
    align-items: center;
  }

  .ant-menu-title-content {
    margin-left: 1.5rem;

    font-size: 1.3rem;
    line-height: 1.5rem; 
  }

  .ant-menu-sub {
    & > .ant-menu-item {
      height: 3.2rem;
      line-height: 3.2rem;
    }
  }
}
