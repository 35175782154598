.apuracao-por-produto {
  .actions-ficha3.ant-space {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-space-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .header-table-produto {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .tag-filter {
      margin-right: 0;
    }

    .container-result-export {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn-result-export {
        margin-right: 1rem;
      }

      .btn-download {
        margin-right: 1rem;
      }
    }
  }
}