@import 'src/app/styles/variables';

$table-body-row-height: 4rem;

.table {
  width: 100%;

  // Table bordered
  .ant-table.ant-table-bordered {
    width: 100%;

    color: $light-gray;
    font-size: 1.3rem;
    line-height: 1.8rem;

    // Table head
    .ant-table-thead {
      .ant-table-cell {
        line-height: 2rem;
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;

        color: $black;
        //background: $lighter-gray;

        // Column with sorter (asc and desc)
        &.ant-table-column-has-sorters {
          .ant-table-filter-column-title {
            padding: 0;
          }
        }

        .ant-table-filter-column {
          .ant-table-filter-trigger-container {
            .ant-table-filter-trigger {
              &.active {
                color: $blue;
              }
            }
          }
        }
      }
    }

    // Table body
    .ant-table-tbody {
      .ant-table-row {
        height: $table-body-row-height;

        &:hover {
          background-color: $light-blue;

          > td {
            background-color: $light-blue;
          }
        }
      }

      .ant-table-cell {
        height: $table-body-row-height;
        padding: 0 1.6rem;

        color: rgba($black, 0.7);

        &.ant-table-selection-column {
          padding: 0;
        }
      }

      .ant-table-cell-fix-right {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $table-body-row-height;
        padding: 0;
      }
    }

    // Table row
    .ant-table-row {
      height: 5rem;
    }

    // Filter column
    .ant-table-filter-column {
      // Filter column button
      .ant-table-filter-trigger-container {
        position: relative;

        &-open {
          background: none;

          .ant-table-filter-trigger {
            color: $primary-color;
          }
        }

        &:hover {
          background: rgba($light-gray, 0.1);

          .ant-table-filter-trigger {
            color: $primary-color;
          }
        }

        // Button icon
        .ant-table-filter-trigger {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 5.5rem;
          color: $light-gray;
        }
      }
    }
  }

  // Pagination
  .ant-pagination.ant-table-pagination {
    .ant-pagination-total-text {
      margin-right: auto;
      padding: 0 1.6rem;

      font-size: 1.2rem;
      color: $light-gray;
    }

    .ant-pagination-item {
      border: none;
    }

    .ant-pagination-item-link {
      border: none;
    }
  }
}

// Column Filter
.ant-dropdown .ant-table-filter-dropdown {
  border: 1rem;
}

.column-filter-dropdown {
  max-width: 30rem;
  min-width: 20rem;
  padding: 1.5rem;

  &__actions {
    margin-top: 1.6rem;

    .ant-btn {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 2.6rem;
      border-radius: 0.6rem;
      text-transform: uppercase;
    }
  }
}

// Column filter toggle
.column-filter-toggle-dropdown {
  .column-filter-dropdown__content {
    .ant-col {
      display: flex;
      width: 100%;

      &:not(:last-of-type) {
        margin-bottom: 1.6rem;
      }

      .ant-switch {
        margin-left: auto;
      }
    }
  }
}
