@import "src/app/styles/variables";

.empresa-subheader {
  width: 100%;
  margin: 1rem 0 0 0;

  .ant-typography {
    margin: 0;
  }

  a {
    display: flex;
  }

  .ant-space {
    width: 100%;
  }

  .matriz-cnpj {
    display: flex;
    align-items: flex-start;

    text-align: left;
  }

  .copy-cnpj-button {
    height: auto;
    padding: 0;

    color: rgba($black, 0.7);
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: normal;
  }
}
