@import "src/app/styles/variables";

.upload-card.ant-card {
  border-color: rgba($light-gray, 0.2);

  .ant-card-head {
    border-color: rgba($light-gray, 0.2);
    height: 6.4rem;

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      color: $light-gray;
      text-align: center;

      > span {
        flex: 1;
        min-width: 0;

        font-size: 1.4rem;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .download-template {
        cursor: pointer;
      }
    }
  }
}

.upload-card-menu {
  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;

    font-size: 1.3rem;

    &-icon {
      margin-right: 0.8rem;
    }
  }
}
