@import "src/app/styles/variables";

.menu-actions {
  .ant-space {
    display: flex;
    align-items: center;

    .ant-space-item {
      display: flex;
      align-self: center;
    }
  }
}

.menu-actions-button {
  border: 0.1rem solid $light-gray;
}

.menu-actions-dropdown {
  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    height: 4rem;
    padding: 0.9rem 2rem;

    font-size: 1.2rem;
    line-height: 2rem;

    &:hover {
      color: $primary-color;
      background: $white;
    }

    .ant-btn {
      padding: 0;
      border: none;

      font-size: 1.2rem;
      line-height: 2rem;
      font-weight: normal;
    }
  }
}
