@import "src/app/styles/variables";

.sidebar-header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: $header-height;
  min-height: $header-height;

  background-color: $white;
  box-shadow: $box-shadow-default;

  &.collapsed {
    .brand {
      display: none;
    }
  }

  .brand {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 2rem;

    font-size: 1.8rem;
    font-weight: bold;
  }

  .sidebar-toggle-button {
    height: 100%;
    margin-left: auto;
    padding: 0 3rem;
    border-radius: 0;
    font-size: 1.6rem;
    color: $primary-color;
  }
}
