@import 'src/app/styles/variables';

.view-by {
  span {
    font-size: 1.3rem;
    color: $gray;
  }

  .ant-radio-group {
    .ant-radio-button-wrapper {
      min-width: 8.5rem;
      text-align: center;

      &-checked {
        span {
          color: $primary-color;
        }
      }
    }
  }
}
