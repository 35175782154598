@import 'src/app/styles/variables';

.worker-card__content {
  display: flex;
  flex-direction: column;

  font-size: 1.3rem;
  font-weight: 400;
  color: rgba($black, 0.7);

  .worker-card__health-check {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }

  .worker-card__prop {
    font-weight: 500;
  }
}

.processos {
}
