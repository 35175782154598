.ficha3 {
  width: 100%;
  height: 100%;

  #luckysheet {
    margin: 0px;
    padding: 0px;
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
  }

  .ant-spin-nested-loading {
    width: 100%;
    height: 100%;

    .ant-spin {
      max-height: 100%;
    }
  }
}
