@import 'src/app/styles/variables';

.modal-view-json {
  // .ant-modal-content {
  //   max-height: 500px;
  // }

  .ant-modal-body {
    overflow: auto;
    max-height: 900px;
  }
}
