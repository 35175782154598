@import "src/app/styles/variables";

.empresa-resultados {
  .ant-row {
    margin: 3rem 0;
  }

  .ant-typography {
    margin-bottom: 0;
    text-align: left;
  }

  .ant-space {
    width: 100%;
  }

  .filter-options {
    .ant-row {
      margin: 0;
    }
  }

  .total {
    &.positive {
      color: $dark-green;
    }

    &.negative {
      color: $dark-red;
    }
  }
}
