.ant-input,
.ant-input-number,
.ant-input-password,
.ant-input-affix-wrapper {
  width: 100%;
  height: 4rem;
  border-radius: $border-radius-default;

  color: $black;
}

.ant-input-number {
  &-input-wrap {
    height: 100%;

    input {
      height: 100%;
    }
  }
}

.ant-input-password,
.ant-input-affix-wrapper {
  input {
    height: 100%;
  }
}
