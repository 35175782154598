.ant-picker {
  width: 100%;
  height: 4rem;

  &.ant-picker-range {
    input {
      text-align: center;
    }
  }
}
