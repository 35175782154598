@import 'src/app/styles/variables';

.modal-confirmation {
  .ant-modal {
    &-body {
      svg {
        color: $orange;
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}
