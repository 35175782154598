.ant-checkbox-wrapper.boxed-checkbox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 6rem;
  padding: 2rem;
  border: solid 2px #d9d9d9;
  border-radius: 4px;
  transition: background 0.3s;

  &.ant-checkbox-wrapper-checked {
    background-color: $light-green;
    color: $dark-green;
    border: solid 2px $lighter-green;
    transition: background 0.3s;

    &:hover {
      background-color: rgba($light-green, 0.1);
    }

    .ant-checkbox-inner {
      background-color: $lighter-green;
      border-color: $lighter-green;
    }
  }

  .ant-checkbox {
    margin-top: -0.4rem;
    margin-right: 0.5rem;
  }

  &:hover:not(.ant-checkbox-wrapper-disabled) {
    color: $dark-green;
    border-color: $green;
    background-color: rgba($light-green, 0.05);
  }

  // Checkbox hover
  &:hover .ant-checkbox-inner,
  &.ant-checkbox-wrapper-checked .ant-checkbox-checked::after,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $green;
  }
}
