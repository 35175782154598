.ant-select {
  width: 100%;
  min-height: 4rem;
  height: 100%;

  .ant-select-selector {
    min-height: 4rem;
  }

  &.ant-select-single {
    .ant-select-selector {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }

  .ant-select-selection-item {
    font-size: 1.3rem;
  }
}

.ant-select-dropdown {
  .ant-select-item {
    font-size: 1.3rem;
  }

  .ant-spin {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    &-text {
      margin-top: 0.5rem;
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
}
