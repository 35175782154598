.usuario-details {
  .ant-input-password {
    height: 4rem;
    border-radius: 0.4rem;

    input {
      height: 3rem;
    }
  }

  .ant-select.ant-select-single {
    .ant-select-selector {
      height: 4rem;
      border-radius: 0.4rem;

      span {
        line-height: 4rem;
      }
    }
  }

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-start;
  }
}
