@import "src/app/styles/variables";

.back-button {
  &.ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 2.4rem;
    height: 2.4rem;
    min-height: 2.4rem;
    padding: 0;

    border-radius: 50%;
    border: none;

    color: $white;
    background-color: $primary-color;

    &:hover {
      color: $white;
      background-color: rgba($primary-color, 0.8);
    }
  }
}
