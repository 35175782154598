@import 'src/app/styles/variables';

.processed-sped {
  .row-remove-row {
    .ant-col {
      display: flex;
      justify-content: flex-end;

      .btn-remove-row {
        margin-left: 1.2rem;
        svg {
          color: $red;
          margin-right: 1rem;
        }
      }
    }
  }
}
