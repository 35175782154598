@import "src/app/styles/variables.scss";

.login-form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 39rem;

    .ant-form-item {
      margin-bottom: 1.8rem;
    }
  }

  .title-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    font-size: 3.5rem;
    font-weight: bold;

    span:first-of-type {
      color: rgba($black, 0.6);
    }

    span:last-of-type {
      color: $primary-color;
    }
  }

  .ant-input-prefix {
   margin-right: 1rem;
  }

  .remember-checkbox {
    color: rgba($black, 0.6);
  }

  .submit-button {
    width: 100%;
  }
}

