@import 'src/app/styles/variables';

.processed-reg-fiscal {
  .row-remove-row {
    .ant-col {
      display: flex;
      justify-content: flex-end;

      .btn-remove-row {
        margin-left: 1.2rem;
        svg {
          color: $red;
          margin-right: 1rem;
        }
      }
    }
  }

  .chave-column-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .link-text-button {
      width: inherit;
    }

    svg {
      color: $blue;
    }
  }
}
