@import "src/app/styles/variables";

.user-menu {
  display: flex;
  height: 100%;

  cursor: pointer;

  // user menu opened
  &.ant-dropdown-open {
    .user-menu__icon {
      color: $white;
      background-color: $primary-color;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.8rem;
    height: 4.8rem;
    margin-right: 1.2rem;
    border-radius: 1rem;

    color: $primary-color;
    background-color: rgba($primary-color, 0.15);

    transition: background-color 0.2s ease-in-out;
  }

  &__detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  // user name
  &__name {
    font-size: 1.3rem;
    line-height: 1.9rem;
    font-weight: bold;
  }

  // user type
  &__user-type {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 1.3rem;
    line-height: 1.9rem;

    svg {
      margin-left: 1.6rem;
    }
  }
}

.user-menu-dropdown {

  .ant-dropdown-menu {
    padding: 0.8rem 0;
    border-radius: $border-radius-default;
  }

  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    padding: 0.8rem 2rem;
  }

  .user-menu-dropdown__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $margin-x-default;
    border-radius: $border-radius-default;

    color: $black;

    &.logout {
      color: $red;
    }
  }

  span {
    color: rgba($black, 0.5);
  }
}
