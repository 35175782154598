.filiais {
  .ant-card-body {
    padding: 0;
  }

  .cnpj-button {
    padding: 0;

    font-size: 1.3rem;
    font-weight: normal;
  }
}
