@import "src/app/styles/variables";

.sidebar {
  overflow: hidden;

  &.ant-layout-sider {
    position: relative;
    z-index: 10;
    background-color: $white;
    box-shadow: $box-shadow-default;

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
    }
  }
}
