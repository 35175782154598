.uppercase {
  text-transform: uppercase;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.first-letter-capitalize:first-letter {
  text-transform: capitalize;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
