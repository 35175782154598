.empresa-arqdig1050 {
  .ant-typography {
    margin-bottom: 0;
    text-align: left;
  }

  .reprocessing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .ant-spin {
      display: flex;
      align-items: center;
    }

    .reprocessing-label {
      font-weight: 500;
      font-size: 1.3rem;
    }
  }

  .filter-options {
    .ant-row {
      margin: 0;
    }
  }

  .ant-btn-link {
    width: 100%;
  }

  .download-regFiscal-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
