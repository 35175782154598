@import "src/app/styles/variables";

.uploader {

  .ant-upload.ant-upload-drag {
    display: flex;
    align-items: center;
    min-height: 15rem;
    border: dashed 2px #AFB9E5;
    border-radius: 4px;
    background: linear-gradient(180deg, #fcfcfc 0%, #f8f9fd);
  }

  .ant-upload-list {
    margin-bottom: 2rem;

    .ant-upload-list-item-name {
      font-size: 1.2rem;
      line-height: 1.5rem;
      text-align: left;
    }

    // Buttons
    .ant-upload-list-item-card-actions-btn {
      min-height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    line-height: 1.5rem;
    color: #8994B0;
    font-size: 1.4rem;

    .icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 50%;

      color: $primary-color;
      background-color: rgba($primary-color, 0.15);
    }
  }

  // Supported files and max size
  &__info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1.1rem;

    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $light-gray;

    .property {
      font-style: italic;
      margin-right: 0.3rem;
    }

    .value {
      font-weight: bold;
    }

    .ant-divider {
      border-left-color: $light-gray;
    }
  }
}
