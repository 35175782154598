@import "src/app/styles/variables";

.subheader {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 4rem;

  margin-bottom: 3.1rem;

  .back-button {
    margin-right: 1.5rem;
  }

  &__title {
    margin: 0;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: bold;
    color: $black;
  }

  &__divider {
    margin: 0 1.1rem;
    color: $light-gray;
  }

  &__subtitle {
    margin: 0 1rem 0 0;
    font-size: 1.6rem;
    line-height: 2.1rem;
    font-weight: normal;
    color: $light-gray;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__actions__left {
    margin-right: auto;
  }

  &__actions__right {
    margin-left: 1rem;
  }
}
