.layout {
    width: 100%;
    height: 100%;
  
    .page-body {
      overflow: auto;
  
      .page-content {
        margin: 4rem;
      }
    }
  }