.modal-xlsx {
  .ant-modal-content {
    width: 100%;
    height: 100%;
  }

  #luckysheet {
    margin: 0px;
    padding: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
  }

  .ant-modal-body {
    position: relative;
    padding: 0;
    height: 100%;
  }

  .ant-spin {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    height: 100%;
  }

  .ant-spin-nested-loading {
    width: 100%;
    height: 100%;

    .ant-spin-container {
      width: 100%;
      height: 100%;
    }

    .ant-spin {
      max-height: 100%;
    }
  }
}
