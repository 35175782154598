@import "src/app/styles/variables";

.modal-detalhes-relatorio {
  .ant-modal-content {
    max-height: 100%;
  }

  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 0;
    }
  }

  .table {
    .ant-table.ant-table-bordered {
      .ant-table-tbody {
        .ant-table-row,
        .ant-table-cell {
          height: 3rem;
          font-size: 1.2rem;
          line-height: 2rem;

          color: rgba($black, 0.7);
        }
      }
    }
  }
}
