@import 'src/app/styles/_variables.scss';

.tag-filter {
  padding: 0.8rem;
  margin-right: 1rem;

  border: 0.1rem solid $gainsboro;
  border-radius: 0.4rem;

  .ant-tag {
    margin-left: 0.5rem;

    &-checkable {
      color: $blue;
      font-size: 14px;
      border: 1px solid $blue;

      &-checked {
        color: $white;
      }
    }
  }
}
