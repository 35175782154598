.modal-arquivo-digital {
  .ant-modal-body {
    padding: 0;
  }

  textarea {
    padding: 2.4rem;
    font-size: 1.3rem;
  }

  .download-button {
    .ant-space-item {
      display: flex;
      align-self: center;
    }
  }
}
