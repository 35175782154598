@import "src/app/styles/variables";

.modal-remove-file {
  .ant-modal {
    &-body {
      svg {
        color: $orange;
      }

      .ant-space {
        width: 100%;

        &-item:last-of-type {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}


