@import 'src/app/styles/variables';

.crud-files {
  .ant-typography {
    margin: 0;
  }

  .ant-divider.ant-divider-horizontal {
    margin-top: 4rem;
  }

  .ant-table.ant-table-bordered {
    .ant-table-tbody {
      .ant-table-cell.ant-table-selection-column {
        padding: 0;
      }
    }
  }
}
