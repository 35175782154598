@import "src/app/styles/variables";

.modal-erros-arquivo-digital {
  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 0;
    }
  }

  .ant-tree-node-content-wrapper {
    // Custom icon
    .ant-tree-iconEle.ant-tree-icon__customize {
      display: inline-flex;
      align-items: center;

      > svg {
        color: $dark-red;
      }
    }
  }

  .ant-tree-title {
    user-select: all;
  }
}
