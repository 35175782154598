.file-schedule-table {
  .ant-typography {
    margin: 3rem 0;
    text-align: left;
  }

  .table {
    .ant-table {
      .ant-table-thead {
        .ant-table-cell.file-result {
          font-size: 1rem;
        }
      }
    }
  }
}
