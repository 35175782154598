@import 'src/app/styles/variables';

.apuracao-por-cnpj {
  .link-button:not(.ant-btn-link[disabled]) {
    color: $red;
  }

  .actions-arquivo-digital {
    width: 9rem;
    display: flex;
    align-items: center;

    .ant-btn-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }

  .subheader {
    margin: 0;

    &__actions {
      .actions {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .btn-result-export {
    margin: 0 1rem;
  }
}
