@import "src/app/styles/variables";

$success-color: #64d46d;

.ant-card.statistic-card {
  min-width: 18rem;
  min-height: 10rem;
  border-radius: 1rem;
  margin: 0;

  border: none;
  box-shadow: none;

  .ant-card-body {
    padding: 1.6rem;
  }

  .ant-statistic {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    .ant-statistic-title {
      margin-bottom: 1rem;

      font-size: 1.3rem;
      line-height: 2rem;
      white-space: nowrap;

      color: rgba($gray, 0.7);
    }

    .ant-statistic-content {
      font-size: 2.8rem;
      line-height: 3rem;
      font-weight: bold;
      white-space: nowrap;

      color: $blue;
    }
  }

  &.success {
    .ant-statistic-content {
      color: $success-color;
    }
  }

  &.danger {
    .ant-statistic-content {
      color: $dark-red;
    }
  }

  &.warning {
    .ant-statistic-content {
      color: $orange;
    }
  }
}
