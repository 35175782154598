@import "src/app/styles/variables";

.header {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 8rem;
  min-height: 8rem;
  padding: 0 2rem 0 0;

  background-color: $white;
  box-shadow: $box-shadow-default;

  &__right {
    display: flex;
    align-items: center;
    margin-left: auto;

    > * {
      &:not(:last-child) {
        margin-right: 3rem;
      }
    }
  }

  .empresa-subheader {
    margin: 0 0 0 3rem;
  }
}
